'use strict';

/**
 * Import type definitions allowing VS Code to show IntelliSense.
 *
 * @typedef {{ expireson: number, data: Xtra }} XtraCache
 *
 * @typedef {import('a11y-dialog')} A11yDialog
 * @typedef {import('../antenne-api').Xtra} Xtra
 * @typedef {import('../antenne-api').LinkXtra} LinkXtra
 * @typedef {import('../antenne-api').OndemandXtra} OndemandXtra
 * @typedef {import('../antenne-frontend').MediaMetadata} MediaMetadata
 * @typedef {import('./Dialog').default} Dialog
 * @typedef {import('./AntenneConfig').default} AntenneConfig
 * @typedef {import('./CommonMethods').default} CommonMethods
 * @typedef {import('./NavigationHandler').default} NavigationHandler
 */
import ClassLogger from 'ClassLogger';
import { ApiClient } from './ApiClient';

export default class XtraService {
    /**
     * Returns the class name used by the ClassLogger.
     *
     * @returns {string}
     */
    getClassName () {
        return 'XtraService';
    }

    /**
     * @param {CommonMethods} commonMethods
     * @param {NavigationHandler} navigationHandler
     * @param {Dialog} dialogService
     */
    constructor (commonMethods, navigationHandler, dialogService, eventEmitter) {
        /** @type {console} */
        this.logger = ClassLogger(this, true); // set second parameter to false to disable logging

        this.commonMethods = commonMethods;
        this.dialogService = dialogService;
        this.navigationHandler = navigationHandler;
        this.eventEmitter = eventEmitter;

        this.apiClient = new ApiClient();

        /**
         * This option is used to store the interval in seconds that is used to poll xtras from the API.
         */
        this.xtraHttpPollTimer = undefined;
        // TTL for cache and polling
        this.cacheDefaultTtlInMilliseconds = 5 * 60 * 1000; // 5min

        /** @type {number | undefined} */
        this.xtraCountdownInterval = undefined;

        /** @type {A11yDialog | undefined} */
        this.xtraWallDialog = undefined;

        /** @type {Node | undefined} */
        this.xtraWallDialogNode = undefined;

        this.cacheKey = 'xtras';

        this.navigationHandler.on('ready', () => this.init());
    }

    /**
     * Initialize the Xtras by checking the local cache or fetch current data from the API.
     */
    async init () {
        if (
            window.nativeJsBridge.isWebview ||
            document.documentElement.classList.contains('template--nativeapp') ||
            document.documentElement.classList.contains('template--accountminimal')
        ) {
            this.logger.log('Not initializing Xtras because of non-default web template/client.');
            return;
        }

        if (this.commonMethods.testLocalStorageSupport() === false) {
            this.logger.log('Not initializing Xtras because of missing localStorage support');
            return;
        }

        this.logger.log('Initializing Xtras and starting to poll');
        this.pollXtrasFromApi();
    }

    /**
     * Poll the xtra API endpoint for information of "current" and "upcoming" content.
     */
    async pollXtrasFromApi () {
        clearTimeout(this.xtraHttpPollTimer);
        this.xtraHttpPollTimer = undefined;

        let timeoutTime = this.cacheDefaultTtlInMilliseconds;
        try {
            const xtras = await this.getXtras();
            if (xtras.current) {
                this.renderCurrent(xtras.current);
            } else {
                this.logger.log('No current xtra, nothing to render.');
                this.removePreviousXtraButton();
            }

            timeoutTime = this.getMillisecondsUntilNextPoll(xtras);
        } catch (error) {
            this.logger.error(error);
        }

        this.logger.log('setting next xtra interval to ' + (new Date(Date.now() + timeoutTime).toISOString()));
        this.xtraHttpPollTimer = setTimeout(() => {
            this.pollXtrasFromApi();
        }, timeoutTime);
    }

    removePreviousXtraButton (currentXtra = null) {
        // Remove any previous xtra
        const previousElement = document.querySelector('.c-xtra');
        if (previousElement) {
            if (currentXtra && previousElement.dataset.xtraid === currentXtra.id) {
                // current is already rendered
                return;
            }
            previousElement.remove();
            this.eventEmitter.emit('xtra:ended', { xtraId: previousElement.dataset.xtraid });
        }
    }

    /**
     * Render the given `xtra` to the user.
     *
     * @param {Xtra['current']}
    */
    renderCurrent (currentXtra) {
        if (!currentXtra) {
            return;
        }

        // stop if xtra is expired
        if (new Date(currentXtra.endtime).getTime() < Date.now()) {
            return;
        }

        if (document.querySelector(`.c-xtra[data-xtraid="${currentXtra.id}"]`)) {
            // xtra is already rendered
            return;
        }

        this.logger.log('Rendering current xtra', currentXtra);

        this.removePreviousXtraButton(currentXtra);

        const player = document.querySelector('.c-player');

        if (player) {
            const button = this.createXtraButtonMarkup(currentXtra);
            player.prepend(button);
        }
    }

    /**
     * Creates and returns an Xtra button element wrapped in a positioned div.
     *
     * @param {Xtra['current']}
     * @returns {HTMLDivElement}
     */
    createXtraButtonMarkup (currentXtra) {
        const button = this.commonMethods.markupToElement(`
            <button class="c-xtra__button">
                ${this.commonMethods.getXtraLogo()}
            </button>
        `);

        button.addEventListener('click', event => {
            event.preventDefault();
            this.openXtraWall();
            return false;
        });

        const buttonWrapper = this.commonMethods.markupToElement(`
            <div class="c-xtra" data-xtraid="${currentXtra.id}"></div>
        `);
        buttonWrapper.append(button);

        return buttonWrapper;
    }

    /**
     * Show the XtraWall to the user.
     */
    async openXtraWall () {
        const currentXtra = await this.getCurrent();
        if (!currentXtra) {
            // prevent errors
            return;
        }

        const id = (Math.random() + 1).toString(36).substring(7);
        this.xtraWallDialogNode = this.commonMethods.markupToElement(
            window.antenne.templates.xtrawall(id),
        );
        document.body.append(this.xtraWallDialogNode);
        this.xtraWallDialog = this.dialogService.initDialogNode(this.xtraWallDialogNode);

        this.xtraWallDialog
            .on('hide', () => clearInterval(this.xtraCountdownInterval))
            .show();

        this.fillXtraWallWithData(currentXtra);
    }

    /**
     * Fill the xtrawall modal with data from the given `xtra`.
     *
     * @param {Xtra['current']} xtra
     */
    fillXtraWallWithData (xtra) {
        const xtrawall = this.xtraWallDialogNode.querySelector('[data-xtrawall]');

        if (!xtrawall) {
            this.logger.warn('Cannot find Xtra wall. Not able to fill the Xtra wall with data');
            return;
        }

        const title = xtrawall.querySelector('[data-xtra-title]');
        if (title) {
            title.textContent = xtra.config.title;
        }

        const description = xtrawall.querySelector('[data-xtra-description]');
        if (description) {
            description.innerHTML = xtra.config.description;
        }

        const picture = xtrawall.querySelector('[data-xtra-picture]');
        if (picture) {
            const images = xtra.config.image;
            const image = this.commonMethods.findMatchingImage(images, '500x500', 'webp') || xtra.config.image[0];

            const imgTag = this.commonMethods.markupToElement(`
                <img src="${image}" alt="${xtra.config.title}" loading="lazy" />
            `);

            picture.append(imgTag);
        }

        this.showXtraCallToAction(xtra, xtrawall);
    }

    /**
     * @param {LinkXtra} xtra
     * @param {HTMLDivElement} xtrawall
     */
    showXtraCallToAction (xtra, xtrawall) {
        if (!this.xtraHasStarted(xtra)) {
            return this.showCountdown(xtra, xtrawall);
        }

        if (new Date(xtra.endtime).getTime() < Date.now()) {
            return this.showExpired(xtrawall);
        }

        switch (xtra.type) {
            case 'link':
                return this.showLink(xtrawall, xtra.config.url);
            case 'ondemand':
                return this.showPlayButton(xtra, xtrawall);
            default:
                this.logger.warn(`Unsupported Xtra type "${xtra.type}". Not adding any action`, xtra);
        }
    }

    /**
     * @param {HTMLDivElement} xtrawall
     * @param {string} url
     * @param {string?} label
     */
    showLink (xtrawall, url, label) {
        /** @type {HTMLAnchorElement | null} */
        const ahref = xtrawall.querySelector('[data-xtra-ahref]');
        if (!ahref) {
            this.logger.warn('Failed to find an anchor element with "data-xtra-ahref" attribute. Cannot create link');
            return;
        }

        ahref.classList.remove('u-hide');
        ahref.href = url;

        if (this.isSameOrigin(url) === false) {
            ahref.target = '_blank';
        }

        if (label) {
            ahref.textContent = label;
        }

        ahref.addEventListener('click', () => {
            this.xtraWallDialog && this.xtraWallDialog.hide();
        });
    }

    isSameOrigin (targetUrl) {
        // check if its same origin
        if (targetUrl.startsWith('/') === false) {
            if (targetUrl.startsWith('https://') === false) {
                return false;
            }
            const clickedUrl = new URL(targetUrl);
            if (clickedUrl.host !== window.location.host) {
                return false;
            }
        }
        return true;
    }

    /**
     * @param {OndemandXtra} xtra
     * @param {HTMLDivElement} xtrawall
     */
    showExpired (xtrawall) {
        const countdown = xtrawall.querySelector('[data-xtra-countdown]');
        if (!countdown) {
            this.logger.warn('Failed to find element with "data-xtra-countdown" attribute. Cannot start countdown');
            return;
        }
        countdown.textContent = 'Xtra bereits abgelaufen.';
        countdown.classList.remove('u-hide');
    }

    /**
     * @param {OndemandXtra} xtra
     * @param {HTMLDivElement} xtrawall
     */
    showCountdown (xtra, xtrawall) {
        const countdown = xtrawall.querySelector('[data-xtra-countdown]');
        if (!countdown) {
            this.logger.warn('Failed to find element with "data-xtra-countdown" attribute. Cannot start countdown');
            return;
        }

        const xtraHasStarted = this.xtraHasStarted(xtra);

        if (xtraHasStarted) {
            countdown.classList.add('u-hide');
            clearInterval(this.xtraCountdownInterval);
            return this.showXtraCallToAction(xtra, xtrawall);
        }

        const remainingMillisecondsToStart = new Date(xtra.starttime).getTime() - Date.now();
        const formattedTime = this.commonMethods.formatMillisecondsToHMS(remainingMillisecondsToStart);
        countdown.textContent = `Verfügbar in ${formattedTime}`;

        if (!this.xtraCountdownInterval) {
            countdown.classList.remove('u-hide');

            this.xtraCountdownInterval = setInterval(() => {
                this.showCountdown(xtra, xtrawall);
            }, 1000);
        }
    }

    /**
     * @param {OndemandXtra} xtra
     * @param {HTMLDivElement} xtrawall
     */
    showPlayButton (xtra, xtrawall) {
        /** @type {HTMLButtonElement | null} */
        const playbutton = xtrawall.querySelector('[data-play-type]');

        if (playbutton) {
            playbutton.classList.remove('u-hide');

            /** @type {MediaMetadata} */
            const metadata = {
                title: 'Xtra',
                seekable: xtra.seekable,
                artist: xtra.config.title,
                cover: this.commonMethods.findMatchingImage(xtra.config.image, '100x100', 'jpg'),
            };

            playbutton.dataset.play = xtra.id;
            playbutton.dataset.metadata = JSON.stringify(metadata);
            playbutton.dataset.playStream = JSON.stringify(xtra.stream);

            playbutton.addEventListener('click', () => {
                this.xtraWallDialog && this.xtraWallDialog.hide();
            });
        }
    }

    /**
     * @param {Xtra['current']} xtra
     *
     * @returns {Boolean}
     */
    xtraHasStarted (xtra) {
        const remainingMillisecondsToStart = new Date(xtra.starttime).getTime() - Date.now();

        return remainingMillisecondsToStart <= 0;
    }

    /**
     * Display an xtra’s outro overlay.
     */
    async openXtraOutroOverlay () {
        this.logger.log('Attempting to show xtra outro overlay');

        const currentXtra = await this.getCurrent();

        if (!currentXtra) {
            this.logger.log('No current xtra available anymore. Nothing to show');
            return;
        }

        if (currentXtra.type !== 'ondemand') {
            this.logger.log('Current xtra is not of type "ondemand". No outro overlay available', currentXtra);
            return;
        }

        if (!currentXtra.overlay || !currentXtra.overlay.outro) {
            this.logger.log('Current xtra is missing an outro overlay. Nothing to show', currentXtra);
            return;
        }

        this.logger.log('Showing outro overlay for current xtra', currentXtra);

        const id = (Math.random() + 1).toString(36).substring(7);
        const dialogNode = this.commonMethods.markupToElement(
            window.antenne.templates.xtrawall(id),
        );
        document.body.append(dialogNode);
        this.xtraWallDialog = this.dialogService.initDialogNode(dialogNode, true);

        this.xtraWallDialog.show();

        const xtrawall = dialogNode.querySelector('[data-xtrawall]');

        if (!xtrawall) {
            this.xtraWallDialog.hide();
            this.logger.warn('Cannot find Xtra wall. Not able to fill the Xtra wall with data');
            return;
        }

        const outro = currentXtra.overlay.outro;

        const title = xtrawall.querySelector('[data-xtra-title]');
        if (title && outro.headline) {
            title.textContent = outro.headline;
        }

        const description = xtrawall.querySelector('[data-xtra-description]');
        if (description && outro.description) {
            description.innerHTML = outro.description;
        }

        const picture = xtrawall.querySelector('[data-xtra-picture]');
        if (picture) {
            const images = currentXtra.config.image;

            const img = this.commonMethods.findMatchingImage(images, '500x500', 'webp') || currentXtra.config.image[0];

            const imgTag = this.commonMethods.markupToElement(`
                <img src="${img}" alt="${currentXtra.config.title}" loading="lazy" />
            `);

            picture.append(imgTag);
        }

        const { link, label } = currentXtra.overlay.outro.button || {};

        if (link && label) {
            this.showLink(xtrawall, link, label);
        }
    }

    /**
     * Returns the "current" xtra. Returns `undefined` if there’s no "current" xtra available.
     *
     * @returns {Xtra['current']}
     */
    async getCurrent () {
        const { current } = await this.getXtras();
        return current;
    }

    /**
     * Fetch Xtras from local storage cache or the API if the cache is expired.
     *
     * @returns {Promise<Xtra>}
     */
    async getXtras () {
        let { expireson, data: xtras } = this.getCachedXtra();

        if (!expireson || expireson < Date.now()) {
            try {
                this.logger.log('Refreshing xtra cache', { expired: expireson });
                xtras = await this.fetchXtrasFromApi();

                localStorage.setItem(this.cacheKey, JSON.stringify({
                    expireson: Date.now() + this.getMillisecondsUntilNextPoll(xtras) - 100,
                    data: xtras,
                }));
                this.logger.log('Got xtras from api', xtras);
            } catch (error) {
                this.logger.warn('Failed to fetch xtras from api', { error });
            }
        } else {
            this.logger.log('Returning cached xtra', xtras);
        }

        return xtras || {};
    }

    getCachedXtra () {
        /** @type {XtraCache} */
        return JSON.parse(localStorage.getItem(this.cacheKey) || '{}');
    }

    /**
     * Returns the seconds until the next poll interval.
     *
     * @param {Xtra | undefined} xtras
     *
     * @returns {Number}
    */
    getMillisecondsUntilNextPoll (xtras) {
        /**
         * This random offset distributes client requests within the given interval.
         * By using the calculated, exact seconds until the Xtra is upcoming would
         * DDoS our API because all clients would send requests to the same time.
         */
        const offsetInMilliseconds = this.commonMethods.randomIntWithin(0, 10) * 1000;

        if (xtras && xtras.current) {
            const millisecondsUntilCurrentEnds = new Date(xtras.current.endtime).getTime() - Date.now();
            if (
                millisecondsUntilCurrentEnds > 0 &&
                millisecondsUntilCurrentEnds <= this.cacheDefaultTtlInMilliseconds
            ) {
                return Math.ceil(millisecondsUntilCurrentEnds + offsetInMilliseconds);
            }
        }

        if (xtras && xtras.upcoming) {
            const millisecondsUntilUpcoming = new Date(xtras.upcoming.publishtime).getTime() - Date.now();

            if (millisecondsUntilUpcoming <= this.cacheDefaultTtlInMilliseconds) {
                return millisecondsUntilUpcoming + offsetInMilliseconds;
            }
        }

        const { expireson } = this.getCachedXtra();
        if (expireson && expireson > Date.now()) {
            // if something is cached, use the cache expiry as next interval
            return expireson - Date.now();
        }
        return this.cacheDefaultTtlInMilliseconds + offsetInMilliseconds;
    }

    /**
     * Fetch available xtras from the API.
     *
     * @returns {Promise<Xtra | undefined>}
    */
    async fetchXtrasFromApi () {
        this.logger.log('Fetching xtras from API');

        try {
            /** @type {Xtra} */
            return await this.apiClient.get('/xtras', { credentials: 'omit' });
        } catch (error) {
            this.logger.warn('Failed to fetch Xtras from API', error);
            throw error;
        }
    }
}
